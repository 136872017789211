<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
export default{
  created(){
    document.title='蜀乡客收银台'
  }
}
</script>
<style lang="less">

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //text-align: center;
  color: #2c3e50;
  width: 100%;
  height: 100%;
}
*{
  margin: 0;
  padding: 0;
}

</style>
