import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '登录',
    component: () => import('../views/login/login.vue')
  },
  {
    path: '/login',
    name: '登录',
    component: () => import('../views/login/login.vue')
  },
  {
    path: '/test',
    name: 'test',
    component: () => import('../views/test/test.vue')
  },
  {
    path: '/index',
    name: '首页',
    redirect: '/goods/goods',
    meta: {
      requireAuth: true,
    },
    component: () => import("../views/index/home.vue"),
    children: [
      // {
      //   path: '/home',
      //   name: '首页',
      //   component: () => import("../views/index/home.vue"),
      // },
      {
        path: '/home',
        name: '首页',
        component: () => import("../views/goods/goods.vue"),
      },
      // {
      //   path: '/goodsReport/goodsReport',
      //   name: '商品报表',
      //   component: () => import("../views/goodsReport/goodsReport.vue"),
      // },
      {
        path: '/order/order',
        name: '订单',
        component: () => import("../views/order/order.vue"),
      },
      {
        path: '/member/member',
        name: '会员',
        component: () => import("../views/member/member.vue"),
      },
      {
        path: '/finance/finance',
        name: '财务',
        component: () => import("../views/finance/finance.vue"),
      },
      {
        path: '/handover/handover',
        name: '交班',
        component: () => import("../views/handover/handover.vue"),
      },
    ]
  },
  // {
  //   path: '/:pathMatch(.*)*',
  //   component: () => import("../views/notFound.vue"),
  // },
]

const router = new VueRouter({
  routes
})
// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push(location, onResolve, onReject) {
//   if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
//   return originalPush.call(this, location).catch(err => err)
// }
// 路由守卫
router.beforeEach((to, from, next) => {
  // 判断是否有token，用户是否登录
  let token = sessionStorage.getItem('token')
  // 如果有token或是去登录页，放行
  if (token || to.path === '/login') {
    next()
    // 如果没有token强制跳转到登录页
  } else {
    next('/login')
  }
})

export default router
