import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    goods_key: ''
  },
  getters: {
  },
  mutations: {
    setGoodsKey(state, data) {
      state.goods_key = data
    }
  },
  actions: {
    changeGoods({ commit }, data) {
      commit('setGoodsKey', data)
    }
  },
  modules: {
  }
})
